
import Vue from "vue";

export default Vue.extend({
  name: "closed-media-view",
  methods: {
    onClickButton: function () {
      window.location.href = "https://corp.vacan.com/";
    },
  },
});
